import { Text } from '@mediahuis/chameleon-react';
import { fontSizeLarge, fontWeightBold } from '@mediahuis/chameleon-theme-wl';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import styled from 'styled-components';
import { brandConfig } from './config';

const PriceMarkdown = styled.span`
  strong {
    font-weight: ${fontWeightBold};
    font-size: ${fontSizeLarge};
  }
`;

const createCompactVitrineCardProps = offerItem => {
  const features = (offerItem.features ?? []).map(sortableFeature => ({
    children: (
      <Text alignSelf="center" fontFamily="system" size="Caption2">
        <MarkdownRender source={sortableFeature.description} />
      </Text>
    ),
    isAvailable: true,
    key: sortableFeature.id,
  }));

  const hardcodedTextDL = 'Of kies voor maandelijks opzegbaar';
  const bottomTextDL = offerItem.priceSentence
    ?.toLowerCase()
    .includes(hardcodedTextDL.toLowerCase())
    ? hardcodedTextDL
    : '';
  const priceSentenceDL = offerItem.priceSentence
    ?.toLowerCase()
    .includes(hardcodedTextDL.toLowerCase())
    ? offerItem.priceSentence
        .toLowerCase()
        .replace(hardcodedTextDL.toLowerCase(), '')
    : offerItem.priceSentence;

  const brandConfigItem = brandConfig.find(item => item.brand === MH_BRAND);

  return {
    features,
    highlight: offerItem.isHighlighted,
    highlightColor:
      brandConfigItem && brandConfigItem.highlightColor
        ? brandConfigItem.highlightColor
        : 'color-primary-base',
    highlightColorText:
      brandConfigItem && brandConfigItem.highlightColorText
        ? brandConfigItem.highlightColorText
        : 'color-text-primary-base',
    label: offerItem.label,
    logoSrc: offerItem.logo ? offerItem.logo : undefined,
    multibrandProps:
      offerItem.isMultiBrand &&
      (MH_GROUP === GROUPS.BE || (MH_GROUP === GROUPS.DL && bottomTextDL))
        ? {
            activeBrand: MH_GROUP === GROUPS.BE ? MH_BRAND : undefined,
            text:
              MH_GROUP === GROUPS.DL ? (
                bottomTextDL
              ) : (
                <>
                  Gratis{' '}
                  <span style={{ fontWeight: 'bold' }}>alle plusartikels</span>
                </>
              ),
          }
        : undefined,
    price: offerItem.priceSentence ? (
      <PriceMarkdown>
        <MarkdownRender
          source={
            MH_GROUP === GROUPS.DL ? priceSentenceDL : offerItem.priceSentence
          }
          styling={{ display: 'flex', flexDirection: 'row' }}
        />
      </PriceMarkdown>
    ) : undefined,
    routeYouBannerProps: offerItem.hasRouteYou
      ? {
          text: (
            <>
              Gratis{' '}
              <span style={{ fontWeight: 'bold' }}>fiets- en wandelapp</span>
            </>
          ),
        }
      : undefined,
    title: (
      <MarkdownRender
        source={offerItem.title.replace(/&nbsp;/g, ' ').trim() || ''}
      />
    ),
  };
};

export default createCompactVitrineCardProps;
